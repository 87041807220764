import { Component, OnInit, OnDestroy } from '@angular/core';
import { AccountsService } from '../services/accounts.service';
import { Router, ActivatedRoute } from '@angular/router';
import { IVoucher } from "../entities/IVoucher";
import { VoucherService } from '../voucher.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { RedeemVoucher } from './redeemvoucher';
import { compare, compareString, compareDates } from '../helpers/sort'
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-home',
  templateUrl: './vouchers.component.html'
})
export class VouchersComponent implements OnDestroy, OnInit {

  public bsModalRef: BsModalRef;
  public nav: any;
  public vouchers: Array<IVoucher>;
  public sortedVouchers: Array<IVoucher>;
  public accountId: string;
  public p: number;

  constructor(private accountsService: AccountsService,
    private activatedroute: ActivatedRoute,
    private router: Router, 
    private voucherService: VoucherService,
    private _toastr: ToastrService,
    private modalService: BsModalService) {
    this.nav = document.querySelector('nav.navbar');
    this.accountId = this.activatedroute.snapshot.params['accountId'];
  }

  public ngOnInit(): any {
    this.nav.className += " white-bg";

    this.loadVouchers();
  }

  public loadVouchers() {
    this.voucherService.getVouchersForAccount(this.accountId).subscribe(
      response => this.handleGetVouchersResponse(response),
      error => this.handleGetVouchersError(error));
  }

  private handleGetVouchersResponse(vouchers: Array<IVoucher>) {
    this.vouchers = vouchers;
    this.sortedVouchers = vouchers;
  }
  private handleGetVouchersError(error : HttpErrorResponse){
    this._toastr.error('Unexpected error - please try again later', 'Failed to Get Vouchers');
  }

  public expires(voucher : IVoucher) : Date {
    var newDT = new Date(voucher.RedeemedAt);
    newDT.setDate(newDT.getDate() + voucher.ValidForInDays);
    return newDT;
  }

  public expired(voucher: IVoucher): boolean {
    return this.expires(voucher) < new Date();
  }

  public redeem() {
    const initialState = {
      accountId: this.accountId,
      parent: this
    };
    this.bsModalRef = this.modalService.show(RedeemVoucher, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  public ngOnDestroy(): any {
    this.nav.classList.remove("white-bg");
  }

  public sortData(sort: Sort) {
    const data = this.vouchers;
    if (!sort.active || sort.direction === '') {
      this.sortedVouchers = data;
      return;
    }

    this.sortedVouchers = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'code': return compareString(a.Code, b.Code, isAsc);
        case 'numberOfDevices': return compare(a.NumberOfDevices, b.NumberOfDevices, isAsc);
        case 'redeemed': return compareDates(a.RedeemedAt, b.RedeemedAt, isAsc);
        case 'expires': return compareDates(this.expires(a), this.expires(b), isAsc);
        case 'expired': return compare(this.expired(a), this.expired(b), isAsc);
      }
    });
  }
}
