import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { VoucherService } from '../voucher.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IOperationResponse } from '../entities/IOperationResponse';
import { VouchersComponent } from './vouchers.component';

@Component({
  selector: 'modal-content',
  templateUrl: './redeemvoucher.html'
})

export class RedeemVoucher implements OnInit {
  title: string = "Redeem a Breeze Booth Voucher";
  accountId: string;
  closeBtnName: string;
  roles: string[];
  codes: Array<string>;
  parent: VouchersComponent;

  public form: FormGroup;
  public voucherCode = new FormControl(undefined, [Validators.required]);

  constructor(public bsModalRef: BsModalRef,
    private voucherService: VoucherService,
    private _toastr: ToastrService) {

    this.form = new FormGroup(
      {
        'voucherCode': this.voucherCode,
      });
  }

  public controlIsValid(controlName: string) {
    const control = this.form.get(controlName);
    return (control.invalid && (control.dirty || control.touched));
  }

  public controlClasses(controlName: string) {
    return { 'is-invalid': this.controlIsValid(controlName) };
  }

  ngOnInit() {
  }

  cancel() {
    this.form.reset();
    this.bsModalRef.hide();
  }

  save() {
    if (this.voucherCode.valid) {
      this.voucherService.redeem(this.accountId, this.voucherCode.value).subscribe(
        response => this.handleRedeemVoucherResponse(response),
        error => this.handleRedeemVoucherError(error));
    }
  }

  private handleRedeemVoucherResponse(response: IOperationResponse) {
    if (response.IsOK) {
      this._toastr.success('Voucher code has been successfully redeemed', 'Voucher Redeemed');
      this.parent.loadVouchers();
      this.bsModalRef.hide();
    }
    else {
      this._toastr.error(response.Message, 'Failed to Redeem Voucher');
    }
  }

  private handleRedeemVoucherError(error: any) {
    this._toastr.error('Unexpected error - please try again later', 'Failed to Redeem Voucher');
  }

}
