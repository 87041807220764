import { Component } from '@angular/core';
import { smoothlyMenu } from '../../../app.helpers';
import { UserContext } from "../../../contexts/UserContext";
import { AuthFactory } from 'src/app/contexts/AuthFactory';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { OkCancel } from 'src/app/okCancel/okCancel';
import { LogoutComponent } from 'src/app/logout/logout.component';

declare var jQuery: any;

@Component({
  selector: 'topnavbar',
  templateUrl: 'topnavbar.template.html'
})
export class TopNavbarComponent {
  private readonly _userContext: UserContext;
  private readonly _authFactory: AuthFactory;

  bsModalRef: BsModalRef;

  constructor(
    userContext: UserContext,
    authFactory: AuthFactory,
    private modalService: BsModalService) {
    this._userContext = userContext;
    this._authFactory = authFactory;
  }

  toggleNavigation(): void {
    jQuery("body").toggleClass("mini-navbar");
    smoothlyMenu();
  }

  logout(): void {
    var auth = this._authFactory.createAuth();
    auth.signOut();
  }

  public isLoggedIn() {
    return this._userContext.isLoggedIn();
  }

  confirmLogout() {
    const initialState = {
      //  user: theUser,
      title: 'Are you sure you want to logout?'
    };
    this.bsModalRef = this.modalService.show(OkCancel, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result === true) {
        this.logout();
      }
    });
  }
}
