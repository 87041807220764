import { Injectable } from '@angular/core';

@Injectable()
export class AuthConfig {

    // Viaduct Cognito
    // public ClientId = 'bmh4d25ne1dolb8uffrhm2cmr';
    // public AppWebDomain = 'breezeboothipad.auth.eu-west-2.amazoncognito.com';
    // public UserPoolId = 'eu-west-2_PNN4Tbans';


    // BreezeBooth Cognito
    public ClientId = '5k47tnnl9rasudi24tg78us948';
    public AppWebDomain = 'breezeboothipad.auth.us-east-1.amazoncognito.com';
    public TokenScopesArray  = ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'];
    public get RedirectUriSignIn(): string { return `${this.appUrl}/callback/`; }
    public get RedirectUriSignOut(): string { return `${this.appUrl}/logout/`; }
    public UserPoolId = 'us-east-1_bWZGdEanW';

    private get appUrl(): string {
        return `${window.location.protocol}//${window.location.host}${this.path}`;
    }

    private get path(): string {
        var pathSegment = window.location.pathname.split('/').filter(s => s === 'production')[0];
        return !!pathSegment ? `/${pathSegment}` : '';
    }
 }