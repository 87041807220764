import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IAccount } from '../entities/IAccount';
import { IAccountSummary } from '../entities/IAccountSummary';
import { IOperationResponse } from '../entities/IOperationResponse';
import { ServiceConfig } from '../ServiceConfig';
import { Observable } from 'rxjs';
import { LoadingObservable } from '../components/common/loading/loading.observable';

interface ISaveAccountDetails {
  accountId: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  constructor(private http: HttpClient) { }

  @LoadingObservable()
  public accountSummaries(): Observable<Array<IAccountSummary>> {
    return this.http.get<Array<IAccountSummary>>(ServiceConfig.APIUrl() + '/accounts/summaries');
  }

  @LoadingObservable()
  public account(accountId: string): Observable<IAccount> {
    return this.http.get<IAccount>(ServiceConfig.APIUrl() + '/accounts/' + accountId);
  }

  @LoadingObservable()
  public accountForCurrentUser() : Observable<IAccount> {
    return this.http.get<IAccount>(ServiceConfig.APIUrl() + '/accounts/current/');
  }

  @LoadingObservable()
  public saveAccount(account: IAccount) : Observable<IOperationResponse> {
    const request = { accountId: account.Id, name: account.Name } as ISaveAccountDetails;
    return this.http.post<IOperationResponse>(ServiceConfig.APIUrl() + '/accounts/', request);
  }
}
