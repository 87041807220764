import { Observable } from 'rxjs';

export function isObservable(arg: any): arg is Observable<any> {
    const castArg = arg as Observable<any>;
    return castArg.do !== undefined
        && castArg.forEach !== undefined
        && castArg.lift !== undefined
        && castArg.operator !== undefined
        && castArg.pipe !== undefined
        && castArg.source !== undefined
        && castArg.subscribe !== undefined
        && castArg.toPromise !== undefined;
}