import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IUser } from "./entities/IUser";
import { IOperationResponse } from './entities/IOperationResponse';
import { ServiceConfig } from "./ServiceConfig";
import { Observable } from 'rxjs';
import { LoadingObservable } from './components/common/loading/loading.observable';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private http: HttpClient) { }

  @LoadingObservable()
  public sync(): Observable<IOperationResponse> {
   return this.http.get<IOperationResponse>(ServiceConfig.APIUrl() + '/users/sync');
  }

  @LoadingObservable()
  public usersForAccount(accountId: string): Observable<Array<IUser>> {
    return this.http.get<Array<IUser>>(ServiceConfig.APIUrl() + '/users/account/' + accountId);
  }
}
