import { Component, OnInit, OnDestroy } from '@angular/core';
import { AccountsService } from '../services/accounts.service';
import { SubscriptionService } from '../subscription.service';
import { PaymentService } from '../payment.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ISubscription, ISubscriptionTotals } from '../entities/ISubscription';
import { ToastrService } from 'ngx-toastr';
import { IOperationResponse } from '../entities/IOperationResponse';
import { HttpErrorResponse } from '@angular/common/http';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { OkCancel } from '../okCancel/okCancel';
import { compare, compareString, compareDates } from '../helpers/sort'
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-home',
  templateUrl: './subscriptions.component.html'
})
export class SubscriptionsComponent implements OnDestroy, OnInit {

  public nav: any;
  public subscriptions: Array<ISubscription>;
  public sortedSubscriptions: Array<ISubscription>;
  public subscriptionTotals: ISubscriptionTotals;
  public accountId: string;
  public p: number;
  public bsModalRef: BsModalRef;

  constructor(
    private accountsService: AccountsService,
    private subscriptionService: SubscriptionService,
    private paymentService: PaymentService,
    private activatedroute: ActivatedRoute,
    private router: Router,
    private _toastr: ToastrService,
    private modalService: BsModalService) {
    this.nav = document.querySelector('nav.navbar');
    this.accountId = this.activatedroute.snapshot.params['accountId'];
  }

  public ngOnInit(): any {
    this.nav.className += ' white-bg';

    this.loadSubscriptions();
  }

  private loadSubscriptions() {
    this.subscriptionService.subscriptions(this.accountId)
      .subscribe(
        response => this.handleSubsServiceResponse(response),
        error => this.handleSubsServiceException(error));

    this.subscriptionService.totals(this.accountId)
      .subscribe(
        response => this.handleSubsTotalsServiceResponse(response),
        error => this.handleSubsTotalsServiceException(error));
  }

  handleSubsTotalsServiceResponse(response: ISubscriptionTotals) {
    this.subscriptionTotals = response;
  }

  handleSubsTotalsServiceException(error: HttpErrorResponse) {
    this._toastr.error('Unexpected error - please try again later', 'Failed to Load Subscription Totals');
  }

  handleSubsServiceResponse(response: ISubscription[]) {
    this.subscriptions = response;
    this.sortedSubscriptions = response;
  }

  handleSubsServiceException(error: HttpErrorResponse) {
    this._toastr.error('Unexpected error - please try again later', 'Failed to Load Subscriptions');
  }

  confirmCancelSubscription(subs: ISubscription) {
    const initialState = {
      //  user: theUser,
      title: 'Are you sure you want to cancel this subscription?'
    };
    this.bsModalRef = this.modalService.show(OkCancel, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result === true) {
        this.cancelSubscription(subs);
      }
    });
  }

  public get licensesInUse(): number {
    if (!this.subscriptionTotals) return 0;
    return (this.subscriptionTotals.InUse + this.subscriptionTotals.VouchersInUse);
  }

  public get licenseTotal(): number {
    if (!this.subscriptionTotals) return 0;
    return (this.subscriptionTotals.SubscriptionTotal + this.subscriptionTotals.VouchersTotal);
  }

  public licenseUsagePercent() {
    if (!this.subscriptionTotals) return '0%';

    return ((this.licensesInUse / this.licenseTotal) * 100).toFixed(0) + '%';
  }

  public refresh() {
    this.loadSubscriptions();
  }

  public cancelSubscription(subs: ISubscription) {
    this.paymentService.cancelSubscription(subs)
      .subscribe((response: IOperationResponse) => this.handlePaymentServiceResponse(response),
        (err) => this.handlePaymentServiceException(err));
  }

  handlePaymentServiceResponse(response: IOperationResponse) {
    if (response.IsOK) {
      this._toastr.success('Subscription will not be renewed.  It will expire at your next payment date.', 'Canceled');
      this.loadSubscriptions();
    } else {
      this._toastr.error(response.Message, 'Failed to Cancel Subscription');
    }
  }

  handlePaymentServiceException(err: HttpErrorResponse) {
    this._toastr.error('Unexpected error - please try again later', 'Failed to Cancel Subscription');
  }

  public canBeCancelled(sub: ISubscription): boolean {
    return sub.ExpiresAt == null;
  }

  public ngOnDestroy(): any {
    this.nav.classList.remove('white-bg');
  }

  public sortData(sort: Sort) {
    const data = this.subscriptions;
    if (!sort.active || sort.direction === '') {
      this.sortedSubscriptions = data;
      return;
    }

    this.sortedSubscriptions = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'numberOfLicenses': return compare(a.NumDeviceLicenses, b.NumDeviceLicenses, isAsc);
        case 'plan': return compare(a.ContractName, b.ContractName, isAsc);
        case 'bluesnapSubscriptionId': return compare(a.BluesnapSubscriptionId, b.BluesnapSubscriptionId, isAsc);
        case 'purchased': return compareDates(a.CreatedAt, b.CreatedAt, isAsc);
        case 'expires': return compareDates(a.ExpiresAt, b.ExpiresAt, isAsc);
      }
    });
  }
}
