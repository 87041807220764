import { Component, OnInit } from '@angular/core';
import { AuthFactory } from 'src/app/contexts/AuthFactory';
import { Router } from '@angular/router';
import { UsersService } from '../users.service';
import { UserContext, BreezeBoothRole } from '../contexts/UserContext';
import { IOperationResponse } from '../entities/IOperationResponse';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {

  constructor(
    private _authFactory: AuthFactory,
    private _router: Router,
    private usersService: UsersService,
    private userContext: UserContext,
    private _toastr: ToastrService) {
  }

  ngOnInit() {
    const auth = this._authFactory.createAuth();
    const curUrl = window.location.href;
    auth.parseCognitoWebResponse(curUrl);
    
    this.usersService.sync().subscribe(
      response => this.handleSyncResponse(response),
      error => this.handleSyncException(error));
  }

  handleSyncResponse(response : IOperationResponse) {
    if (response.IsOK) {
      if (this.userContext.isInRole(BreezeBoothRole.SystemAdmin)) {
        this._router.navigate(['/accounts']);
      } else if (this.userContext.isInRole(BreezeBoothRole.Owner)) {
        this._router.navigate(['/account-summary/']);
      } else {
        this._router.navigate(['/home']);
      }
    } else {
      const toast = this._toastr.error(response.Message, 'Login Failed');
      toast.onTap.subscribe(() => {
        const auth = this._authFactory.createAuth();
        auth.signOut()
      });
    }
  }

  handleSyncException(error: HttpErrorResponse) {
    this._toastr.error('Unexpected error - please try again later', 'Login Failed');
  }
}

