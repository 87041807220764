import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceConfig } from './ServiceConfig';
import { ICreateOperationResponse } from './entities/ICreateOperationResponse';
import { Observable } from 'rxjs';
import { LoadingObservable } from './components/common/loading/loading.observable';

interface ICreateTransaction {
  AccountId: string;
  BlueSnapProductId: number;
  BlueSnapSkuId: number;
}

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(private http: HttpClient) { }

  @LoadingObservable()
  public createTransaction(
    accountId: string,
    blueSnapProductId: number,
    blueSnapSkuId: number): Observable<ICreateOperationResponse> {

    const request = { 
      AccountId: accountId,
      BlueSnapProductId: blueSnapProductId, 
      BlueSnapSkuId: blueSnapSkuId 
    } as ICreateTransaction;

    return this.http.post<ICreateOperationResponse>(ServiceConfig.APIUrl() + '/transaction/add', request);
  }
}
