export class ServiceConfig {
  static APIUrl(): string {
    // BREEZE (Prod)
    return "https://v2.breezebooth.io/api";

    // VIADUCT (Dev)
    // return 'https:///hbkgebju94.execute-api.eu-west-2.amazonaws.com/Prod/api';

    // LOCAL
    //return 'http://localhost:54270/api';
  }
}
