import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { VoucherService } from '../voucher.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { IVoucher } from '../entities/IVoucher';
import { IEditVouchersResponse } from '../entities/IEditVoucherResponse';

@Component({
    selector: 'modal-content',
    templateUrl: './editvoucher.component.html'
  })
  
  export class EditVouchers implements OnInit {
    public title: string;
    public closeBtnName: string;
    public form: FormGroup;
    public id = new FormControl({ value: undefined, disabled: true }, [Validators.required]);
    public numberOfDevices = new FormControl(undefined, [Validators.required, Validators.min(1)]);
    public notes = new FormControl(undefined);
    public onClose: Subject<boolean>;
    @Input() public voucher: IVoucher;

    constructor(
        public bsModalRef: BsModalRef,
        private voucherService: VoucherService,
        private _toastr: ToastrService) {

    }

    public ngOnInit() {
        this.onClose = new Subject();
        this.form = new FormGroup(
            {
              'id': this.id,
              'numberOfDevices': this.numberOfDevices,
              'notes': this.notes,
            });
        if (this.voucher) {
            this.setupForm(this.voucher);
        }
    }

    public controlIsValid(controlName: string) {
        const control = this.form.get(controlName);
        return (control.invalid && (control.dirty || control.touched));
    }
    
    public controlClasses(controlName: string) {
        return { 'is-invalid': this.controlIsValid(controlName) };
    }

    public cancel() {
        this.form.reset();
        this.onClose.next(false);
        this.bsModalRef.hide();
    }

    public save() {
        if (this.numberOfDevices.valid &&
            this.notes.valid) {
            this.voucherService.edit({
                VoucherId: this.id.value,
                NumberOfDevices: this.numberOfDevices.value,
                Notes: this.notes.value

            }).subscribe(
                response => this.handleResponse(response),
                error => this.handleError(error)
            )
        }
    }

    private handleResponse(response: IEditVouchersResponse) {
        if (response.IsOK) {
            this._toastr.success('voucher was successfully updated', 'Vouchers Edited');
            this.onClose.next(true);
            this.bsModalRef.hide();
        } else {
            this._toastr.error(response.Message, 'Failed to Edit Vouchers');
        }
    }

    private handleError(error) {
        this._toastr.error('Unexpected error - please try again later', 'Failed to Edit Vouchers');
    }

    private setupForm(voucher: IVoucher) {
        this.id.setValue(voucher.Id);
        this.numberOfDevices.setValue(voucher.NumberOfDevices);
        this.notes.setValue(voucher.Description);
    }
}