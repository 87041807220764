// All fields are nullable - if a field is not specified, it's not included in the search
export class ISearchVouchersRequest {
  // false - Just unredeemed vouchers (those not associated with an account)
  // true - Just redeemed vouchers (those associated with an account)
  IsRedeemed: boolean;

  // false - vouchers not yet expired
  // true - vouchers that have expired
  IsExpired: boolean;

  // Search for vouchers created within a specified date range
  CreatedAtStart: Date;
  CreatedAtEnd: Date;

  // Search for vouchers redeemed within a specified date range
  RedeemedAtStart: Date;
  RedeemedAtEnd: Date;
  
  // Search for vouchers redeemed by a specific account
  ForAccountId: string;
}
