import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceConfig } from './ServiceConfig';
import { ISubscription, ISubscriptionTotals } from './entities/ISubscription';
import { Observable } from 'rxjs';
import { LoadingObservable } from './components/common/loading/loading.observable';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  constructor(private http: HttpClient) { }

  @LoadingObservable()
  public subscriptions(accountId: string): Observable<ISubscription[]> {
    return this.http.get<Array<ISubscription>>(ServiceConfig.APIUrl() + '/subscription/' + accountId);
  }

  @LoadingObservable()
  public totals(accountId: string): Observable<ISubscriptionTotals> {
    return this.http.get<ISubscriptionTotals>(ServiceConfig.APIUrl() + '/subscription/licenses/' + accountId);
  }
}
