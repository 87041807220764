import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserContext } from '../contexts/UserContext';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  private readonly _userContext: UserContext;
  private readonly _router: Router;

  constructor(userContext: UserContext, router: Router) {
    this._userContext = userContext;
    this._router = router;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (this._userContext.isLoggedIn()) {
      return true;
    } else {
      this._router.navigate(['/login']);
    }
    return false;
  }
}