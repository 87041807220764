import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountsService } from '../services/accounts.service';
import { IAccount } from '../entities/IAccount';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { IOperationResponse } from '../entities/IOperationResponse';
import { ShopperService } from '../services/shopper.service';
import { IShopper } from '../entities/IShopper';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html'
})
export class AccountComponent implements OnDestroy, OnInit {

  public nav: any;
  public mode: String = 'display';
  public origName: string;
  public newAccountName: string;
  public accountId: string;
  public account: IAccount;
  public shopper: IShopper;

  constructor(
    private accountsService: AccountsService,
    private shopperService: ShopperService,
    private activatedroute: ActivatedRoute,
    private router: Router,
    private _toastr: ToastrService) {
    this.nav = document.querySelector('nav.navbar');
    this.accountId = this.activatedroute.snapshot.params['accountId'];
  }

  public ngOnInit(): any {
    this.nav.className += ' white-bg';

    const that = this;
    this.accountsService.account(this.accountId).subscribe(
      account => this.handleLoadAccount(account),
      error => this.handleLoadAccountException(error));
  }

  private handleLoadAccount(account : IAccount) {
    this.account = account;
  }
  private handleLoadAccountException(error : HttpErrorResponse) {
    this._toastr.error('Unexpected error - please try again later', 'Failed to Get Account');
  }

  public ngOnDestroy(): any {
    this.nav.classList.remove('white-bg');
  }

  getNotification(evt) {
    this.newAccountName = this.account.Name;
    this.mode = 'edit';
  }

  public cancel(): void {
    this.newAccountName = '';
    this.mode = 'display';
  }

  public save(): void {
    this.origName = this.account.Name;
    this.account.Name = this.newAccountName;
    this.accountsService.saveAccount(this.account).subscribe(
      response => this.handleSaveAccountResponse(response),
      error => this.handleSaveAccountException(error));
  }

  private handleSaveAccountResponse(response : IOperationResponse) {
    if (response.IsOK) {
      this._toastr.success(this.newAccountName, 'Saved');
    } else{
      this._toastr.error(response.Message, 'Failed to Save');
      this.account.Name = this.origName;
    }
    this.mode = 'display';
  }
  private handleSaveAccountException(error : HttpErrorResponse) {
    this._toastr.error("An unexpected error occurred", "Failed to Save");
    this.account.Name = this.origName;
    this.mode = 'display';
  }
}
