export function compare(a: number | string | Date | Boolean, b: number | string | Date | Boolean, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

export function compareString(a: string, b: string, isAsc: boolean) {
    a = a || '';
    b = b || '';
    return (a.toLowerCase() < b.toLowerCase() ? -1 : 1) * (isAsc ? 1 : -1);
}

export function compareDates(a: Date, b: Date, isAsc: boolean) {
    a = a || new Date(1900, 1, 1);
    b = b || new Date(1900, 1, 1);
    a = new Date(a);
    b = new Date(b);

    return (a.getTime() < b.getTime() ? -1 : 1) * (isAsc ? 1 : -1);
}