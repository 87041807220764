import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'modal-ok-cancel',
  templateUrl: './okCancel.html'
})

export class OkCancel implements OnInit {
  title: string;
  closeBtnName: string;
  public onClose: Subject<boolean>;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() { 
    this.onClose = new Subject();
  }

  cancel() {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  save() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }
}
