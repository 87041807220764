import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserContext, IRolePermissions } from '../contexts/UserContext';
import { AccountsService } from '../services/accounts.service';
import { Router, ActivatedRoute } from '@angular/router';
import { IAccount } from '../entities/IAccount';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthFactory } from '../contexts/AuthFactory';
import { ISubscriptionTotals } from '../entities/ISubscription';
import { SubscriptionService } from '../subscription.service';

@Component({
  selector: 'app-account-summary',
  templateUrl: './account-summary.component.html',
  styleUrls: ['./account-summary.component.css']
})
export class AccountSummaryComponent implements OnDestroy, OnInit {

  public nav: any;
  public account: IAccount;
  public accountId: string;
  public loggedIn: boolean;
  public rolePermissions: IRolePermissions;
  public subscriptionTotals: ISubscriptionTotals;

  constructor(
    private accountsService: AccountsService,
    private subscriptionService: SubscriptionService,
    private activatedroute: ActivatedRoute,
    private router: Router,
    private userContext: UserContext,
    private _toastr: ToastrService,
    private _authFactory: AuthFactory)  {
      this.nav = document.querySelector('nav.navbar');

      this.accountId = this.activatedroute.snapshot.params['accountId'];

      this.rolePermissions = this.userContext.userRoles();
    }

  ngOnInit() {
    this.nav.className += ' white-bg';

    if (this.accountId === undefined && this.userContext.isLoggedIn()) {
      this.loggedIn = true;
      this.accountsService.accountForCurrentUser().subscribe(
        account => this.handleLoadAccount(account), 
        error => this.handleLoadAccountException(error));
    }
  }

  loginClick() {
    const auth = this._authFactory.createAuth();
    auth.getSession();
  }

  public get licensesInUse(): number {
    if (!this.subscriptionTotals) return 0;
    return (this.subscriptionTotals.InUse + this.subscriptionTotals.VouchersInUse);
  }

  public get licenseTotal(): number {
    if (!this.subscriptionTotals) return 0;
    return (this.subscriptionTotals.SubscriptionTotal + this.subscriptionTotals.VouchersTotal);
  }

  public licenseUsagePercent() {
    if (!this.subscriptionTotals) return '0%';

    return ((this.licensesInUse / this.licenseTotal) * 100).toFixed(0) + '%';
  }


  private handleLoadAccount(account: IAccount) {
    this.account = account;
    this.accountId = account.Id;
    this.loadSubscriptionTotals();
  }
  private handleLoadAccountException(error: HttpErrorResponse) {
    this._toastr.error('Unexpected error - please try again later', 'Failed to Get Account');
  }

  private loadSubscriptionTotals() {
    if (!this.accountId) return;
    this.subscriptionService.totals(this.accountId).subscribe(
      response => this.handleLoadSubscriptions(response),
      error => this.handleLoadSubscriptionsException(error)
    )
  }

  private handleLoadSubscriptions(response: ISubscriptionTotals) {
    this.subscriptionTotals = response
  }

  private handleLoadSubscriptionsException(error: any) {
    this._toastr.error('Unexpected error - please try again later', 'Failed to load subscriptions');
  }

  public ngOnDestroy(): any {
    this.nav.classList.remove('white-bg');
  }
}
