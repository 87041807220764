import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CreateVouchers } from './createvouchers';
import { EditVouchers } from './editvoucher.component';
import { IVoucher } from '../entities/IVoucher';
import { VoucherService } from '../voucher.service';
import { ISearchVouchersRequest } from '../entities/ISearchVouchersRequest';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Sort } from '@angular/material/sort';
import { OkCancel } from 'src/app/okCancel/okCancel';

@Component({
  selector: 'app-voucheradmin',
  templateUrl: './voucheradmin.component.html'
})
export class VoucherAdminComponent implements OnInit {
  bsModalRef: BsModalRef;
  vouchers: Array<IVoucher>;
  sortedVouchers: IVoucher[];
  p: number;

  constructor(private modalService: BsModalService,
    private voucherService: VoucherService,
    private _toastr: ToastrService) { }

  ngOnInit() {
    this.loadVouchers();
  }

  private loadVouchers() {
    const searchRequest = new ISearchVouchersRequest;
    this.voucherService.search(searchRequest).subscribe(
      vouchers => this.handleLoadVouchers(vouchers),
      error => this.handleLoadVouchersException(error));
  }

  private handleLoadVouchers(vouchers: IVoucher[]) {
    this.vouchers = vouchers;
    this.sortedVouchers = this.vouchers.slice();
  }

  private handleLoadVouchersException(error: HttpErrorResponse) {
    this._toastr.error('Unexpected error - please try again later', 'Failed to Get Vouchers');
  }

  public expires(voucher: IVoucher): Date {
    if (voucher.RedeemedAt) {
      const newDT = new Date(voucher.RedeemedAt);
      newDT.setDate(newDT.getDate() + voucher.ValidForInDays);
      return newDT;
    }
    return voucher.RedeemBy;
  }

  public create() {
    this.bsModalRef = this.modalService.show(CreateVouchers);
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result === true) {
        this.loadVouchers();
      }
    });
  }

  edit(voucher: IVoucher) {
    this.bsModalRef = this.modalService.show(EditVouchers, { initialState: { voucher: voucher } });
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result === true) {
        this.loadVouchers();
      }
    });
  }

  confirmDelete(voucherId) {
    const initialState = {
      //  user: theUser,
      title: 'Are you sure you want to delete this voucher?'
    };
    this.bsModalRef = this.modalService.show(OkCancel, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result === true) {
        this.deleteVoucher(voucherId);
      }
    });
  }

  deleteVoucher(voucherId) {
    this.voucherService.delete(voucherId).subscribe(
      response => this.handleDeleteVouchersResponse(response),
      error => this.handleDeleteVouchersError(error));
  }

  handleDeleteVouchersResponse(response) {
    this.loadVouchers();
  }

  handleDeleteVouchersError(error) {
    this._toastr.error('Unexpected error - please try again later', 'Failed to delete Voucher');
  }

  sortData(sort: Sort) {
    const data = this.vouchers.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedVouchers = data;
      return;
    }

    this.sortedVouchers = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'code': return compareString(a.Code, b.Code, isAsc);
        case 'numberOfDevices': return compare(a.NumberOfDevices, b.NumberOfDevices, isAsc);
        case 'description': return compareString(a.Description, b.Description, isAsc);
        case 'account': return compareString(a.AccountName, b.AccountName, isAsc);
        case 'redeemed': return compareDate(a.RedeemedAt, b.RedeemedAt, isAsc);
        case 'expires': return compareDate(this.expires(a), this.expires(b), isAsc);
        default: return 0;
      }
    });

    function compare(a: number | Boolean, b: number | Boolean, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    function compareString(a: string, b: string, isAsc: boolean) {
      a = a || '';
      b = b || '';
      return (a.toLowerCase() < b.toLowerCase() ? -1 : 1) * (isAsc ? 1 : -1);
    }

    function compareDate(a: Date, b: Date, isAsc: boolean) {
      a = a || new Date(1900, 1, 1);
      b = b || new Date(1900, 1, 1);
      a = new Date(a);
      b = new Date(b);

      return (a.getTime() < b.getTime() ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }
}
