import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceConfig } from './ServiceConfig';
import { ICreditCard } from './entities/ICreditCard';
import { IShopper } from './entities/IShopper';
import { ISubscription } from './entities/ISubscription';
import { IPlan } from './entities/IPlan';
import { IOperationResponse } from './entities/IOperationResponse';
import { Observable } from 'rxjs';
import { LoadingObservable } from './components/common/loading/loading.observable';

interface ISaveCreditCardDetails {
  token: string;
  accountId: string;
  creditCard: ICreditCard;
}
interface IAddSubscription {
  accountId: string;
  quantity: number;
  plan: IPlan;
}

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) { }

  public token(): Observable<string> {
    return this.http.get<string>(ServiceConfig.APIUrl() + '/payment/token');
  }

  @LoadingObservable()
  public saveCardDetails(
    hostedPaymentFieldsToken: string,
    accountId: string,
    last4Digits: string,
    expiryDate: string,
    cardType: string,
    shopper: IShopper): Observable<IOperationResponse> {
    const card: ICreditCard = {
      Id: "00000000-0000-0000-0000-000000000000", Last4Digits: last4Digits, ExpiryDate: expiryDate, CardType: cardType, Version: 0, CreatedAt: new Date(), UpdatedAt: null };
    const request = {
      token: hostedPaymentFieldsToken,
      accountId: accountId,
      creditCard: card,
      shopper: shopper
     } as ISaveCreditCardDetails;
    return this.http.post<IOperationResponse>(ServiceConfig.APIUrl() + '/payment/saveCreditCardDetails', request);
  }

  @LoadingObservable()
  public buySubscription(
    accountId: string,
    plan: IPlan,
    quantity: number): Observable<IOperationResponse> {
    const request = { accountId: accountId, quantity: quantity, plan: plan } as IAddSubscription;
    return this.http.post<IOperationResponse>(ServiceConfig.APIUrl() + '/subscription/add', request);
  }

  @LoadingObservable()
  public cancelSubscription(
    subs: ISubscription): Observable<IOperationResponse> {
    return this.http.post<IOperationResponse>(ServiceConfig.APIUrl() + '/subscription/cancel', subs);
  }
}
