import { Component } from '@angular/core';
import { Router } from '@angular/router';
import 'jquery-slimscroll';
import { UserContext, IRolePermissions } from 'src/app/contexts/UserContext';
import { AuthFactory } from 'src/app/contexts/AuthFactory';
import { AccountsService } from 'src/app/services/accounts.service';
import { IAccount } from 'src/app/entities/IAccount';

declare var jQuery: any;

@Component({
  selector: 'navigation',
  templateUrl: 'navigation.template.html'
})

export class NavigationComponent {
  private readonly _userContext: UserContext;
  public username: string;
  userRoles: string[];
  isLoggedIn: boolean;
  accountId: string;

  rolePermissions: IRolePermissions;

  constructor(private router: Router,
    userContext: UserContext,
    private _authFactory: AuthFactory,
    private accountsService: AccountsService) {
    this._userContext = userContext;

    this.username = this._userContext.username();
    this.userRoles = this._userContext.roles();
    this.isLoggedIn = this._userContext.isLoggedIn();
    this.rolePermissions = this._userContext.userRoles();

    if (this.accountId === undefined && this.isLoggedIn) {
      this.accountsService.accountForCurrentUser().subscribe(
        account => this.handleLoadAccount(account));
      }
  }

  private handleLoadAccount(account: IAccount) {
    this.accountId = account.Id;
  }

  ngAfterViewInit() {
    jQuery('#side-menu').metisMenu();

    if (jQuery('body').hasClass('fixed-sidebar')) {
      jQuery('.sidebar-collapse').slimscroll({
        height: '100%'
      });
    }
  }

  activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) > -1;
  }

  loginClick() {
    const auth = this._authFactory.createAuth();
    auth.getSession();
  }
}
