import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent, SafePipe } from './app.component';
import { NavComponent } from './nav/nav.component';
import { EventsComponent } from './events/events.component';
import { UsersComponent } from './users/users.component';
import { EditUser } from './users/editUser';

import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { VouchersComponent } from './vouchers/vouchers.component';
import { RedeemVoucher } from './vouchers/redeemvoucher';
import { EventGroupsComponent } from './eventgroups/eventgroups.component';
import { DevicesComponent } from './devices/devices.component';
import { ModalModule } from 'ngx-bootstrap/modal';

import { BsDropdownModule } from 'ngx-bootstrap';

import { BasicLayoutComponent } from './components/common/layouts/basicLayout.component';
import { BlankLayoutComponent } from './components/common/layouts/blankLayout.component';
import { TopNavigationLayoutComponent } from './components/common/layouts/topNavigationLayout.component';

import { NavigationComponent } from './components/common/navigation/navigation.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { TopNavbarComponent } from './components/common/topnavbar/topnavbar.component';
import { TopNavigationNavbarComponent } from './components/common/topnavbar/topnavigationnavbar.component';
import { LoadingComponent } from './components/common/loading/loading.component';

import { IboxtoolsComponent } from './components/common/iboxtools/iboxtools.component';
import { IboxtoolsEditComponent } from './components/common/iboxtools/iboxtoolsedit.component';
import { IboxtoolsAddComponent } from './components/common/iboxtools/iboxtoolsadd.component';

import { LoginComponent } from './views/appviews/login.component';
import { UserContext } from './contexts/UserContext';

import { AuthInterceptor } from './interceptors/AuthInterceptor';
import { ErrorInterceptor } from './interceptors/ErrorInterceptor';
import { CallbackComponent } from './callback/callback.component';
import { AuthFactory } from './contexts/AuthFactory';
import { AuthConfig } from './contexts/AuthConfig';
import { LogoutComponent } from './logout/logout.component';
import { AccountsComponent } from './accounts/accounts.component';
import { AccountComponent } from './account/account.component';
import { AddSubscriptionComponent } from './add-subscription/add-subscription.component';
import { BuyNow } from './add-subscription/buyNow';
import { AccountSummaryComponent } from './account-summary/account-summary.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import {NgxPaginationModule} from 'ngx-pagination';
import {MatSortModule} from '@angular/material/sort';
import { VoucherAdminComponent } from './voucheradmin/voucheradmin.component';
import { CreateVouchers } from './voucheradmin/createvouchers';
import { EditVouchers } from './voucheradmin/editvoucher.component';

import { Cookie } from 'ng2-cookies/ng2-cookies';
import { AcceptCookies } from './accept-cookies/accept-cookies.component';
import { OkCancel } from './okCancel/okCancel';
import { LoadingService } from './components/common/loading/loadingService';

@NgModule({
  declarations: [
    AppComponent,
    SafePipe,
    NavComponent,
    FooterComponent,
    BasicLayoutComponent,
    BlankLayoutComponent,
    NavigationComponent,
    TopNavigationLayoutComponent,
    TopNavbarComponent,
    TopNavigationNavbarComponent,
    LoadingComponent,
    LoginComponent,
    IboxtoolsComponent,
    IboxtoolsEditComponent,
    IboxtoolsAddComponent,
    SubscriptionsComponent,
    EventsComponent,
    UsersComponent,
    EditUser,
    VouchersComponent,
    RedeemVoucher,
    EventGroupsComponent,
    DevicesComponent,
    CallbackComponent,
    LogoutComponent,
    AccountsComponent,
    AccountComponent,
    AddSubscriptionComponent,
    BuyNow,
    AccountSummaryComponent,
    VoucherAdminComponent,
    CreateVouchers,
    EditVouchers,
    AcceptCookies,
    OkCancel
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    NgxPaginationModule,
    MatSortModule
  ],
  entryComponents: [
    EditUser,
    OkCancel,
    BuyNow,
    CreateVouchers,
    EditVouchers,
    AcceptCookies,
    RedeemVoucher
  ],
  exports: [
    FooterComponent,
    BasicLayoutComponent,
    BlankLayoutComponent,
    NavigationComponent,
    TopNavigationLayoutComponent,
    TopNavbarComponent,
    TopNavigationNavbarComponent
  ],
  providers: [
    Cookie,
    UserContext,
    LoadingService,
    AuthFactory,
    AuthConfig,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
