import { Injectable } from '@angular/core';
import { LoadingBus } from './bus/loading.bus';
import { LoadingBusFactory } from './factory/loading.bus.factory';
import { ILoadingNotification } from './interfaces/loading.notification';

@Injectable()
export class LoadingService {
    private loadingBus: LoadingBus = LoadingBusFactory.create();

    public get dataBeingLoaded(): Array<ILoadingNotification> {
        return this.loadingBus.dataBeingLoaded;
    }

    public get loading(): boolean {
        return this.loadingBus.loading;
    }
}