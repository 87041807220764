import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserContext } from '../contexts/UserContext';
import { AccountsService } from '../services/accounts.service';
import { Router, ActivatedRoute } from '@angular/router';
import { IAccount } from '../entities/IAccount';
import { ISku } from '../entities/ISku';
import { SkuService } from '../sku.service';
import { TransactionService } from '../transaction.service';
import { ToastrService } from 'ngx-toastr';
import { ICreateOperationResponse } from '../entities/ICreateOperationResponse';
import { HttpErrorResponse } from '@angular/common/http';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BuyNow } from './buyNow';

@Component({
  selector: 'app-add-subscription',
  templateUrl: './add-subscription.component.html'
})
export class AddSubscriptionComponent implements OnDestroy, OnInit {

  public nav: any;
  account: IAccount;
  skus: Array<ISku>;
  accountId: string;
  buyNowDisabled: boolean = false;
  buyNowUrl: string;
  bsModalRef: BsModalRef;

  constructor(
    private accountsService: AccountsService,
    private skuService: SkuService,
    private transactionService: TransactionService,
    private activatedroute: ActivatedRoute,
    private router: Router,
    private userContext: UserContext,
    private _toastr: ToastrService,
    private modalService: BsModalService,
    private _router: Router) {

    this.nav = document.querySelector('nav.navbar');

    this.accountId = this.activatedroute.snapshot.params['accountId'];
  }

  ngOnInit() {
    this.nav.className += ' white-bg';

    this.loadAccount();
    this.loadSkus();
  }

  private loadAccount() {
    this.accountsService.account(this.accountId).subscribe(
      response => this.handleAccountServiceResponse(response),
      error => this.handleAccountServiceException(error));
  }
  handleAccountServiceResponse(response: IAccount) {
    this.account = response;
  }
  handleAccountServiceException(error: HttpErrorResponse) {
    this._toastr.error('Unexpected error - please try again later', 'Failed to Get Account');
  }

  private loadSkus() {
    this.skuService.skus().subscribe(
      response => this.handleSkuServiceResponse(response),
      error => this.handleSkuServiceException(error));
  }
  handleSkuServiceResponse(response: ISku[]) {
    this.skus = response;
  }
  handleSkuServiceException(error: HttpErrorResponse) {
    this._toastr.error('Unexpected error - please try again later', 'Failed to Get SKUs');
  }

  openModalWithBuyNow(sku: ISku) {
    this.buyNowDisabled = true;
    this.transactionService.createTransaction(this.accountId, sku.ProductId, sku.SkuId).subscribe(
      response => this.handleTransactionServiceResponse(response, sku),
      error => this.handleTransactionServiceErrorResponse(error));
  }
  handleTransactionServiceResponse(response: ICreateOperationResponse, sku: ISku) {
    if (response.IsOK) {
      const initialState = {
        sku: sku,
        transactionId: response.Id,
        ownerUsername: this.getAccountOwnerUsername(this.account),
        title: 'Buy Now: ' + sku.Name
      };
      this.bsModalRef = this.modalService.show(BuyNow, { initialState });
      this.bsModalRef.content.closeBtnName = 'Close';
      this.bsModalRef.setClass("modal-lg");
      this.bsModalRef.content.onClose.subscribe(result => {
        if (result === true) {
          this._router.navigate(['/subscriptions/' + this.accountId]);
        }
      });
    } else {
      this._toastr.error(response.Message, 'Failed to start Buy Now transaction - please try again later');
    }
    this.buyNowDisabled = false;
  }
  handleTransactionServiceErrorResponse(error: HttpErrorResponse) {
    this._toastr.error('Unexpected error - please try again later', 'Failed to create transaction');
    this.buyNowDisabled = false;
  }

  public ngOnDestroy(): any {
    this.nav.classList.remove('white-bg');
  }

  private getAccountOwnerUsername(account: IAccount) {
    var owner = account.Users.find(u => u.IsOwner);
    return !!owner ? owner.Username : "Could not find owner username";
  }
}
