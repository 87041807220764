import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IUser } from '../entities/IUser';
import { IOperationResponse } from '../entities/IOperationResponse';
import { ServiceConfig } from '../ServiceConfig';
import { Observable } from 'rxjs';
import { IEditedRoles } from '../users/editUser';
import { LoadingObservable } from '../components/common/loading/loading.observable';

interface IAddUserRequest {
  Username: string;
  Name: string;
  Email: string
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient) { }

  @LoadingObservable()
  public addUser(addUserRequest: IAddUserRequest): Observable<any> {
    return this.http.post<IOperationResponse>(ServiceConfig.APIUrl() + '/users/', addUserRequest);
  }

  @LoadingObservable()
  public delete(user: IUser): Observable<IOperationResponse> {
    return this.http.post<IOperationResponse>(ServiceConfig.APIUrl() + '/users/delete/', { user });
  }

  @LoadingObservable()
  public roles(user: IUser): Observable<string[]> {
    return this.http.get<string[]>(ServiceConfig.APIUrl() + '/users/' + user.Username + '/roles');
  }

  @LoadingObservable()
  public updateUser(user: IUser, editedRoles: IEditedRoles) {
    return this.http.put(ServiceConfig.APIUrl() + '/users', { user, editedRoles });
  }
}
