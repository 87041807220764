import { Observable, Subject } from 'rxjs';
import { LoadingBus } from '../bus/loading.bus';
import { LoadingBusFactory } from '../factory/loading.bus.factory';

export class PatchedSubscriber {
    private loadingBus: LoadingBus = LoadingBusFactory.create();

    public subscribe<T>(key: string, observable: Observable<T>): Observable<T> {
        this.loadingBus.dispatch(key, true);
        var hotObservable = this.makeHot(observable);
        hotObservable.subscribe(
            () => this.loadingBus.dispatch(key, false),
            () => this.loadingBus.dispatch(key, false));
        return hotObservable;
    }

    public onDestroy() {
        this.loadingBus.clear();
    }

    private makeHot<T>(coldObservable: Observable<T>): Observable<T> {
        const subject = new Subject();
        coldObservable.subscribe(subject);
        return new Observable((observer) => subject.subscribe(observer));
    }
}