import { Component, OnInit, OnDestroy } from '@angular/core';
import { DevicesessionService } from '../devicesession.service';
import { Router, ActivatedRoute } from '@angular/router';
import { IDevice } from "../entities/IDevice";
import { IDeviceSession } from "../entities/IDeviceSession";
import { ToastrService } from 'ngx-toastr';
import { IOperationResponse } from '../entities/IOperationResponse';
import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { OkCancel } from '../okCancel/okCancel';
import { compare, compareString, compareDates } from '../helpers/sort'

@Component({
  selector: 'app-home',
  templateUrl: './devices.component.html'
})
export class DevicesComponent implements OnDestroy, OnInit {

  public nav: any;
  deviceSessions: Array<IDeviceSession>;
  sortedDeviceSessions: IDeviceSession[];
  devices: Array<IDevice>;
  sortedDevices: IDevice[];
  accountId: string;
  p: number;
  bsModalRef: BsModalRef;

  constructor(private deviceSessionService: DevicesessionService,
    private activatedroute: ActivatedRoute,
    private router: Router,
    private _toastr: ToastrService,
    private modalService: BsModalService) {
    this.nav = document.querySelector('nav.navbar');
    this.accountId = this.activatedroute.snapshot.params['accountId'];
  }

  public ngOnInit(): any {
    this.nav.className += " white-bg";

    this.loadActiveDeviceSessions();
    this.loadRegisteredDevices();
  }

  public mostRecentUser(device: IDevice): string {
    if (device.DeviceSessions != null && device.DeviceSessions.length > 0) {
      return device.DeviceSessions[0].User.Username;
    }
    return "";
  }

  public lastContact(device: IDevice): Date {
    if (device.DeviceSessions != null && device.DeviceSessions.length > 0) {
      if (device.DeviceSessions[0].UpdatedAt != undefined) {
        return device.DeviceSessions[0].UpdatedAt;
      }

      return device.DeviceSessions[0].CreatedAt;
    }
    return null;
  }

  confirmDeactivate(deviceSession: IDeviceSession) {
    const initialState = {
      title: 'Are you sure you want to disable this device?'
    };
    this.bsModalRef = this.modalService.show(OkCancel, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result === true) {
        this.deactivate(deviceSession);
      }
    });
  }

  public deactivate(deviceSession: IDeviceSession) {
    this.deviceSessionService.deactivate(deviceSession).subscribe(
      response => this.handleDeactiveResponse(response),
      error => this.handleDeactivateException(error));
  }

  handleDeactiveResponse(response: IOperationResponse) {
    if (response.IsOK) {
      this._toastr.success('Device deactivated', 'Success');
      this.loadActiveDeviceSessions();
    } else {
      this._toastr.error(response.Message, 'Deactivation Failed');
    }
  }
  handleDeactivateException(error: HttpErrorResponse) {
    this._toastr.error('Unexpected error - please try again later', 'Deactivation Failed');
  }

  conmfirmEnable(device: IDevice) {
    const initialState = {
      title: 'Are you sure you want to enable this device?'
    };
    this.bsModalRef = this.modalService.show(OkCancel, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result === true) {
        this.enable(device);
      }
    });
  }

  public enable(device: IDevice) {
    this.deviceSessionService.enable(device).subscribe(
      response => this.handleEnableResponse(response),
      error => this.handleEnableException(error));
  }
  private handleEnableResponse(response: IOperationResponse) {
    if (response.IsOK) {
      this._toastr.success('Device enabled', 'Success');
      this.loadRegisteredDevices();
    } else {
      this._toastr.error(response.Message, 'Enable Failed');
    }
  }
  private handleEnableException(error: HttpErrorResponse) {
    this._toastr.error('Unexpected error - please try again later', 'Enable Failed');
  }

  confirmDisable(device: IDevice) {
    const initialState = {
      //  user: theUser,
      title: 'Are you sure you want to disable this device?'
    };
    this.bsModalRef = this.modalService.show(OkCancel, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result === true) {
        this.disable(device);
      }
    });
  }

  public disable(device: IDevice) {
    this.deviceSessionService.disable(device).subscribe(
      response => this.handleDisableResponse(response),
      error => this.handleDisableException(error));
  }

  private handleDisableResponse(response: IOperationResponse) {
    if (response.IsOK) {
      this._toastr.success('Device disabled', 'Success');
      this.loadRegisteredDevices();
      this.loadActiveDeviceSessions();
    } else {
      this._toastr.error(response.Message, 'Disable Failed');
    }
  }
  private handleDisableException(error: HttpErrorResponse) {
    this._toastr.error('Unexpected error - please try again later', 'Disable Failed');
  }

  confirmDelete(device: IDevice) {
    const initialState = {
      //  user: theUser,
      title: 'Are you sure you want to delete this device?'
    };
    this.bsModalRef = this.modalService.show(OkCancel, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result === true) {
        this.delete(device);
      }
    });
  }

  public delete(device: IDevice) {
    this.deviceSessionService.delete(device).subscribe(
      response => this.handleDeleteResponse(response),
      error => this.handleDeleteException(error));
  }
  private handleDeleteResponse(response: IOperationResponse) {
    if (response.IsOK) {
      this._toastr.success('Device deleted', 'Success');
      this.loadRegisteredDevices();
      this.loadActiveDeviceSessions();
    } else {
      this._toastr.error(response.Message, 'Delete Failed');
    }
  }
  private handleDeleteException(error: HttpErrorResponse) {
    this._toastr.error('Unexpected error - please try again later', 'Delete Failed');
  }

  public ngOnDestroy(): any {
    this.nav.classList.remove("white-bg");
  }

  public reload() {
    this.loadActiveDeviceSessions();
    this.loadRegisteredDevices();
  }

  private loadActiveDeviceSessions() {
    this.deviceSessionService.active(this.accountId).subscribe(
      deviceSessions => this.handleLoadActiveResponse(deviceSessions),
      error => this.handleLoadActiveException(error));
  }
  private handleLoadActiveResponse(deviceSessions: Array<IDeviceSession>) {
    this.deviceSessions = deviceSessions;
    this.sortedDeviceSessions = this.deviceSessions.slice();
  }
  private handleLoadActiveException(error: HttpErrorResponse) {
    this._toastr.error('Unexpected error - please try again later', 'Load Active Devices Failed');
  }

  private loadRegisteredDevices() {
    this.deviceSessionService.registered(this.accountId).subscribe(
      devices => this.handleLoadRegisteredResponse(devices),
      error => this.handleLoadRegisteredException(error));
  }
  private handleLoadRegisteredResponse(devices: Array<IDevice>) {
    this.devices = devices;
    this.sortedDevices = devices.slice();
  }
  private handleLoadRegisteredException(error: HttpErrorResponse) {
    this._toastr.error('Unexpected error - please try again later', 'Load Registered Devices Failed');
  }


  sortData(sort: Sort) {
    const data = this.deviceSessions.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedDeviceSessions = data;
      return;
    }

    this.sortedDeviceSessions = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'device': return compareString(a.Device.Name, b.Device.Name, isAsc);
        case 'type': return compareString(a.Device.DeviceType.Description, b.Device.DeviceType.Description, isAsc);
        case 'vendor': return compareString(a.Device.VendorId, b.Device.VendorId, isAsc);
        case 'user': return compareString(a.User.Username, b.User.Username, isAsc);
        case 'loggedon': return compareDates(a.CreatedAt, b.CreatedAt, isAsc);
        case 'lastcontact': return compareDates(a.UpdatedAt, b.UpdatedAt, isAsc);
        default: return 0;
      }
    });
  }

  sortRegistered(sort: Sort) {
    const data = this.devices.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedDevices = data;
      return;
    }

    this.sortedDevices = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'device': return compareString(a.Name, b.Name, isAsc);
        case 'type': return compareString(a.DeviceType.Description, b.DeviceType.Description, isAsc);
        case 'vendor': return compareString(a.VendorId, b.VendorId, isAsc);
        case 'lastuser': return compare(this.mostRecentUser(a), this.mostRecentUser(b), isAsc);
        case 'lastcontact': return compareDates(this.lastContact(a), this.lastContact(b), isAsc);
        default: return 0;
      }
    });
  }
}
