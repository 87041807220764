import { Component, Input } from '@angular/core';
import { Cookie } from 'ng2-cookies/ng2-cookies';

@Component({
  selector: 'accept-cookies',
  templateUrl: './accept-cookies.component.html',
  styleUrls: ['./accept-cookies.styles.css']
})
export class AcceptCookies {
  private readonly KEY: string = 'accepted-cookie-policy';
  public hasAcceptedCookiePolicy = false;

  constructor() {
    this.hasAcceptedCookiePolicy = !!Cookie.get(this.KEY) || false;
  }

  public acceptCookies() {
      this.hasAcceptedCookiePolicy = true;
      Cookie.set(this.KEY, this.hasAcceptedCookiePolicy.toString(), undefined, "/");
  }
}
