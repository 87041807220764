import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IDeviceSession } from './entities/IDeviceSession';
import { IDevice } from './entities/IDevice';
import { ServiceConfig } from './ServiceConfig';
import { IOperationResponse } from './entities/IOperationResponse';
import { Observable } from 'rxjs';
import { LoadingObservable } from './components/common/loading/loading.observable';

@Injectable({
  providedIn: 'root'
})
export class DevicesessionService {
  constructor(private http: HttpClient) { }

  @LoadingObservable()
  public active(accountId: string): Observable<Array<IDeviceSession>> {
    return this.http.get<Array<IDeviceSession>>(ServiceConfig.APIUrl() + '/devicesession/active/' + accountId);
  }
  
  @LoadingObservable()
  public registered(accountId: string): Observable<Array<IDevice>> {
    return this.http.get<Array<IDevice>>(ServiceConfig.APIUrl() + '/device/inactive/' + accountId);
  }

  @LoadingObservable()
  public deactivate(deviceSession: IDeviceSession) : Observable<IOperationResponse> {
    return this.http.post<IOperationResponse>(ServiceConfig.APIUrl() + "/devicesession/deactivate", deviceSession);
  }

  @LoadingObservable()
  public enable(device: IDevice) : Observable<IOperationResponse> {
    return this.http.post<IOperationResponse>(ServiceConfig.APIUrl() + "/device/enable/" + device.Id, null);
  }

  @LoadingObservable()
  public disable(device: IDevice) : Observable<IOperationResponse> {
    return this.http.post<IOperationResponse>(ServiceConfig.APIUrl() + "/device/disable/" + device.Id, null);
  }

  @LoadingObservable()
  public delete(device: IDevice) : Observable<IOperationResponse> {
    return this.http.post<IOperationResponse>(ServiceConfig.APIUrl() + "/device/delete/" + device.Id, null);
  }
}
