import { Component, OnInit } from '@angular/core';
import { AuthFactory } from 'src/app/contexts/AuthFactory';

@Component({
  selector: 'login',
  templateUrl: 'login.template.html'
})

export class LoginComponent implements OnInit { 

  constructor(    
    private _authFactory: AuthFactory) {    
  }

  ngOnInit() {
    var auth = this._authFactory.createAuth();
    auth.getSession();
  }
}

