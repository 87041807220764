import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ISku } from './entities/ISku';
import { ServiceConfig } from './ServiceConfig';
import { Observable } from 'rxjs';
import { LoadingObservable } from './components/common/loading/loading.observable';

@Injectable({
  providedIn: 'root'
})
export class SkuService {
  constructor(private http: HttpClient) { }

  @LoadingObservable()
  public skus(): Observable<Array<ISku>> {
    return this.http.get<Array<ISku>>(ServiceConfig.APIUrl() + '/sku');
  }
}
