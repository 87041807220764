import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { UserContext, BreezeBoothRole } from '../contexts/UserContext';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private _userContext: UserContext;

  constructor(userContext: UserContext) {
    this._userContext = userContext;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this._userContext.isLoggedIn()) {
      req = req.clone({
        setHeaders: {
          'Accept': 'application/json',
          'Authorization': `${this._userContext.userToken()}`,
        },
      });
    } else {
      req = req.clone({
        setHeaders: {
          'Accept': 'application/json'
        },
      });
    }

    return next.handle(req);
  }
}
