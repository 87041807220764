import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IUser } from '../entities/IUser';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { UsersService } from '../users.service';
import { IOperationResponse } from '../entities/IOperationResponse';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpErrorResponse } from '@angular/common/http';
import { EditUser } from './editUser';
import { UserContext, BreezeBoothRole } from '../contexts/UserContext';
import { Sort } from '@angular/material/sort';
import { OkCancel } from '../okCancel/okCancel';

@Component({
  selector: 'app-home',
  templateUrl: './users.component.html'
})
export class UsersComponent implements OnDestroy, OnInit {

  public nav: any;
  users: Array<IUser>;
  sortedData: IUser[];
  id: string;
  add: boolean;
  public isDisabled: boolean = true;
  bsModalRef: BsModalRef;
  isOwner: boolean;
  p: number;

  public form: FormGroup;
  public email = new FormControl(undefined, [Validators.required, Validators.email]);
  public username = new FormControl(undefined, [Validators.required, Validators.pattern('^[^ ]*$')]);
  public name = new FormControl(undefined, [Validators.required]);

  constructor(private activatedroute: ActivatedRoute,
    private userService: UserService,
    private usersService: UsersService,
    private _toastr: ToastrService,
    private modalService: BsModalService,
    private _userContext: UserContext) {
    this.nav = document.querySelector('nav.navbar');
    this.id = this.activatedroute.snapshot.params['accountId'];
    this.isOwner = this._userContext.isInRole(BreezeBoothRole.Owner);


    this.form = new FormGroup(
      {
        'email': this.email,
        'username': this.username,
        'name': this.name
      });
  }

  public controlIsValid(controlName: string) {
    const control = this.form.get(controlName);
    return (control.invalid && (control.dirty || control.touched));
  }

  public controlClasses(controlName: string) {
    return { 'is-invalid': this.controlIsValid(controlName) };
  }

  public feedbackClasses(controlName: string) {
    return { 'is-invalid-feedback': this.controlIsValid(controlName) };
  }

  getNotification() {
    this.add = true;
  }

  cancel() {
    this.form.reset();
    this.add = false;
  }

  public ngOnInit(): any {
    this.nav.className += ' white-bg';

    this.loadUsers();
  }
 
  loadUsers() {
    this.usersService.usersForAccount(this.id).subscribe(users => {
      this.users = users;
      this.sortedData = this.users.slice();
    });
  }

  public addUser() {
    this.markControlsAsDirty([this.email, this.username, this.name]);
    if (this.email.valid && this.username.valid && this.name.valid) {
      const request = {
        'Name': this.name.value,
        'Username': this.username.value,
        'Email': this.email.value
      }
      this.userService.addUser(request)
        .subscribe(
          (response: any) => {
            this.handleUserServiceResponse(response)
          },
          error => {
            this._toastr.error('Unable to add this user', 'Failed to add User');
          });
    }
  }

  handleUserServiceResponse(response: IOperationResponse) {
    if (response.IsOK) {
      this._toastr.success('The new user will receive an email with a temporary password.', 'User Added');
      this.loadUsers();
      this.form.reset();
      this.add = false;
    } else {
      this._toastr.error(response.Message, 'Failed to add User');
    }
  }

  public isDeletable(user: IUser) {
    return !user.IsOwner;
  }


  confirmDelete(user: IUser) {
    const initialState = {
      //  user: theUser,
      title: 'Are you sure you want to delete this user?'
    };
    this.bsModalRef = this.modalService.show(OkCancel, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result === true) {
        this.delete(user);
      }
    });
  }

  public delete(user: IUser) {
    this.userService.delete(user).subscribe(
      response => this.handleDeleteResponse(response),
      error => this.handleDeleteException(error));
  }

  private markControlsAsDirty(controls: FormControl[]) {
    controls.forEach(c => this.markAsDirty(c));
  }

  private markAsDirty(formControl: FormControl) {
    if (!formControl.dirty) {
      formControl.markAsDirty();
    }
  }

  private handleDeleteResponse(response: IOperationResponse) {
    if (response.IsOK) {
      this._toastr.success('User deleted', 'Success');
      this.loadUsers();
    } else {
      this._toastr.error(response.Message, 'Delete Failed');
    }
  }
  private handleDeleteException(error: HttpErrorResponse) {
    this._toastr.error('Unexpected error - please try again later', 'Delete Failed');
  }


  public ngOnDestroy(): any {
    this.nav.classList.remove('white-bg');
  }

  openModalWithComponent(theUser: IUser) {
    const initialState = {
      user: theUser,
      title: 'Edit'
    };
    this.bsModalRef = this.modalService.show(EditUser, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  sortData(sort: Sort) {
    const data = this.users.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'username': return compareString(a.Username, b.Username, isAsc);
        case 'name': return compareString(a.Name, b.Name, isAsc);
        case 'email': return compareString(a.Email, b.Email, isAsc);
        case 'devices': return compare(a.MaxNumDeviceLicenses, b.MaxNumDeviceLicenses, isAsc);
        case 'active': return compareDate(a.LastActiveTimestamp, b.LastActiveTimestamp, isAsc);
        case 'trusted': return compare(a.IsTrusted, b.IsTrusted, isAsc);
        case 'owner': return compare(a.IsOwner, b.IsOwner, isAsc);
        default: return 0;
      }
    });

    function compare(a: number | string | Date | Boolean, b: number | string | Date | Boolean, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    function compareString(a: string, b: string, isAsc: boolean) {
      a = a || '';
      b = b || '';
      return (a.toLowerCase() < b.toLowerCase() ? -1 : 1) * (isAsc ? 1 : -1);
    }

    function compareDate(a: Date, b: Date, isAsc: boolean) {
      a = a || new Date(1900, 1, 1);
      b = b || new Date(1900, 1, 1);
      a = new Date(a);
      b = new Date(b);

      return (a.getTime() < b.getTime() ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }
}
