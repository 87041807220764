import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IShopper } from '../entities/IShopper';
import { ServiceConfig } from '../ServiceConfig';
import { Observable } from 'rxjs';
import { LoadingObservable } from '../components/common/loading/loading.observable';

@Injectable({
    providedIn: 'root'
})
export class ShopperService {
    constructor(private http: HttpClient) { }

    @LoadingObservable()
    public getShopper(vaultedShopperId: number): Observable<IShopper> {
        return this.http.get<IShopper>(`${ServiceConfig.APIUrl()}/Shopper/Get/${vaultedShopperId}`);
    }
}