import { Component, OnInit } from '@angular/core';
import { ISku } from '../entities/ISku';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'modal-content',
  templateUrl: './buyNow.html'
})

export class BuyNow implements OnInit {
  title: string;
  closeBtnName: string;
  sku: ISku;
  transactionId: string;
  ownerUsername: string;
  buyNowUrl: string;
  public onClose: Subject<boolean>;

  constructor(public bsModalRef: BsModalRef) {
  }

  ngOnInit() {
    this.onClose = new Subject();

    this.buyNowUrl = this.sku.Url.Url +
                     "&cart.open=Y" +
                     "&merchanttransactionid=" + this.transactionId +
                     "&custom1=" + this.ownerUsername +
                     "&skinId=28043";
  }

  cancel() {
    this.bsModalRef.hide();
    this.onClose.next(true);
  }
}

