import { Component } from '@angular/core';
import { LoadingService } from './loadingService';

@Component({
  selector: 'loading',
  templateUrl: 'loading.template.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
    public get loading(): boolean {
      return this.loadingService.loading;
    }

    constructor(
      private readonly loadingService: LoadingService) {

    }
}