import { CognitoAuth } from 'amazon-cognito-auth-js';
import { Injectable } from '@angular/core';
import { AuthConfig } from './AuthConfig';
import { Router } from '@angular/router';

@Injectable()
export class AuthFactory {

  constructor(private _config: AuthConfig, private _router: Router) { }

  public createAuth(): CognitoAuth {
    const auth = new CognitoAuth(this._config);

    auth.userhandler = {
      onSuccess: function (result) {
        // this._router.navigate(['/accounts']);
      },
      onFailure: function (err) {
        alert('Error!');
      }
    };

    return auth;
  }
}
