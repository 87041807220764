import { Subject } from 'rxjs';
import { ILoadingNotification } from '../interfaces/loading.notification';

export class LoadingBus {
    private _source = new Subject<ILoadingNotification>();
    private _data: Array<ILoadingNotification> = [];
    public events$ = this._source.asObservable();
 
    constructor() {
        this.events$.subscribe(notification => this.handleNotification(notification));
    }

    public dispatch(key: string, loading: boolean) {
        this._source.next({key: key, value: loading});
    }

    public clear() {
        this._data = [];
    }

    public get dataBeingLoaded(): Array<ILoadingNotification> {
        return this._data.filter(item => item.value);
    }

    public get loading(): boolean {
        var loading = this.dataBeingLoaded.length > 0;
        if (!loading) {
            this.clear();
        }

        return loading;
    }

    private handleNotification(notification: ILoadingNotification) {
        var item = this._data.find(i => i.key === notification.key);
        if (!item) {
            this._data.push(notification);
        } else {
            item.value = notification.value;
        }
    }
}