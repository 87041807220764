import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import { ToastrService } from 'ngx-toastr';
import { AuthConfig } from './AuthConfig';
import { Injectable } from '@angular/core';

@Injectable()
export class UserContext {

  constructor(private _toastr: ToastrService, private _authConfig: AuthConfig) { }

  poolData() {
    return {
      UserPoolId: this._authConfig.UserPoolId,
      ClientId: this._authConfig.ClientId
    };
  }

  cognitoUser(): CognitoUser {
    const poolData = this.poolData();
    const userPool = new CognitoUserPool(poolData);
    return userPool.getCurrentUser();
  }

  session() {
    const cognitoUser = this.cognitoUser();
    if (cognitoUser) {
      return cognitoUser.getSession(function (err, session) {
        if (err) {
          return null;
        }
        return session;
      });
    }
    return null;
  }

  isLoggedIn(): boolean {
    return this.session() ? this.session().isValid() : false;
  }

  userToken(): string {
    return this.session() ? this.session().idToken.jwtToken : '';
  }

  userEmail(): string {
    return this.session() ? this.session().idToken.payload.email : '';
  }

  userSub(): string {
    return this.session() ? this.session().idToken.payload.sub : '';
  }

  username(): string {
    return this.session() ? this.session().idToken.payload['cognito:username'] : '';
  }

  roles(): string[] {
    if(!this.session()) {
      return [];
    }
    const res = this.session().idToken.payload['cognito:groups'];
    return res ? res : [];
  }

  isInRole(role: BreezeBoothRole): boolean {
    const roles = this.roles();
    return roles.indexOf(role) > -1;
  }

  userRoles(): IRolePermissions {
    const userRoles = this.roles();
    return {
      accounts: userRoles.includes('SystemAdmin'),
      users: userRoles.includes('Owner'),
      devices: userRoles.includes('Owner') || userRoles.includes('iPadAdmin'),
      events: userRoles.includes('Owner'),
      subscriptions: userRoles.includes('Owner') || userRoles.includes('SubscriptionAdmin'),
      vouchers: userRoles.includes('Owner'),
      creditcard: userRoles.includes('Owner'),
      voucheradmin: userRoles.includes('SystemAdmin')
    };
  }
}

export enum BreezeBoothRole {
  SystemAdmin = 'SystemAdmin',
  IpadAdmin = 'IPadAdmin',
  IPadUser = 'iPadUser',
  SubscriptionAdmin = 'SubscriptionAdmin',
  EventEditor = 'EventEditor',
  Owner = 'Owner',
  EventAdmin = 'EventAdmin'
}

export interface IRolePermissions {
  accounts: boolean;
  users: boolean;
  devices: boolean;
  events: boolean;
  subscriptions: boolean;
  vouchers: boolean;
  creditcard: boolean;
  voucheradmin: boolean;
}
