import { Component, OnInit, OnDestroy } from '@angular/core';
import { AccountsService } from '../services/accounts.service';
import { Router,ActivatedRoute } from '@angular/router';
import { IEvent } from "../entities/IEvent";

@Component({
  selector: 'app-home',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})

export class EventsComponent implements OnDestroy, OnInit {

  public nav: any;
  events: Array<IEvent>;
  accountId: string;
  p: number;

  constructor(private accountsService: AccountsService,
              private activatedroute:ActivatedRoute,
              private router:Router,) {
    this.nav = document.querySelector('nav.navbar');
    this.accountId = this.activatedroute.snapshot.params['accountId'];
  }

  public ngOnInit(): any {
    this.nav.className += " white-bg";

    // Events TODO
    this.events = new Array<IEvent>();
  }

  public ngOnDestroy(): any {
    this.nav.classList.remove("white-bg");
  }  
}
