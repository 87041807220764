import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BlankLayoutComponent } from './components/common/layouts/blankLayout.component';
import { BasicLayoutComponent } from './components/common/layouts/basicLayout.component';

import { AccountsComponent } from './accounts/accounts.component';
import { AccountComponent } from './account/account.component';
import { UsersComponent } from './users/users.component';
import { DevicesComponent } from './devices/devices.component';
import { EventsComponent } from './events/events.component';
import { EventGroupsComponent } from './eventgroups/eventgroups.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { AddSubscriptionComponent } from './add-subscription/add-subscription.component';
import { VouchersComponent } from './vouchers/vouchers.component';
import { VoucherAdminComponent } from './voucheradmin/voucheradmin.component';
import { AccountSummaryComponent } from './account-summary/account-summary.component';

import { AuthGuard } from './auth/auth.guard';
import { CallbackComponent } from './callback/callback.component';
import { LogoutComponent } from './logout/logout.component';

const routes: Routes = [

  // Main redirect
  { path: '', redirectTo: '/home', pathMatch: 'full' },

  {
    path: '', component: BlankLayoutComponent,
    children: [
      { path: 'callback', component: CallbackComponent }
    ]
  },
  {
    path: '', component: BlankLayoutComponent,
    children: [
      { path: 'logout', component: LogoutComponent }
    ]
  },
  {
    path: '', component: BasicLayoutComponent,
    children: [
      { path: 'home', component: AccountSummaryComponent }
    ]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'accounts', component: AccountsComponent }
    ]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'account/:accountId', component: AccountComponent }
    ]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'subscriptions/:accountId', component: SubscriptionsComponent }
    ]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'add-subscription/:accountId', component: AddSubscriptionComponent }
    ]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'users/:accountId', component: UsersComponent }
    ]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'events/:accountId', component: EventsComponent }
    ]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'eventgroups/:accountId', component: EventGroupsComponent }
    ]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'devices/:accountId', component: DevicesComponent }
    ]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'vouchers/:accountId', component: VouchersComponent }
    ]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'voucheradmin', component: VoucherAdminComponent }
    ]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'account-summary/:accountId', component: AccountSummaryComponent }
    ]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'account-summary', component: AccountSummaryComponent }
    ]
  },

  // Handle all other routes
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
