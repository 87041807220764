import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ServiceConfig } from './ServiceConfig';
import { ICreateVouchersRequest } from './entities/ICreateVouchersRequest';
import { IEditVoucherRequest } from './entities/IEditVouchersRequest';
import { ICreateVouchersResponse } from './entities/ICreateVouchersResponse';
import { IEditVouchersResponse } from './entities/IEditVoucherResponse';
import { IOperationResponse } from './entities/IOperationResponse';
import { Observable } from 'rxjs';
import { IVoucher } from './entities/IVoucher';
import { ISearchVouchersRequest } from './entities/ISearchVouchersRequest';
import { LoadingObservable } from './components/common/loading/loading.observable';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {
  constructor(private http: HttpClient) { }

  @LoadingObservable()
  public create(request: ICreateVouchersRequest) : Observable<ICreateVouchersResponse> {
    return this.http.post<ICreateVouchersResponse>(ServiceConfig.APIUrl() + "/voucher/create", request);
  }

  @LoadingObservable()
  public edit(request: IEditVoucherRequest) : Observable<IEditVouchersResponse> {
    return this.http.post<ICreateVouchersResponse>(ServiceConfig.APIUrl() + "/voucher/edit", request);
  }

  @LoadingObservable()
  public delete(voucherId: string): Observable<IOperationResponse> {
    return this.http.delete<IOperationResponse>(ServiceConfig.APIUrl() + "/voucher/" + voucherId);
  }

  @LoadingObservable()
  public getVouchersForAccount(accountId: string) : Observable<Array<IVoucher>> {
    return this.http.get<Array<IVoucher>>(ServiceConfig.APIUrl() + "/voucher/account/" + accountId);
  }

  @LoadingObservable()
  public redeem(accountId: string, code: string) : Observable<IOperationResponse> {
    const request = { 
      AccountId: accountId,
      Code: code
    } as IRedeemVoucherRequest;
    return this.http.post<IOperationResponse>(ServiceConfig.APIUrl() + "/voucher/redeem", request);
  }

  @LoadingObservable()
  public search(request: ISearchVouchersRequest) : Observable<Array<IVoucher>> {
    let params = new HttpParams();
    if (request.IsRedeemed !== undefined) params.set('IsRedeemed', request.IsRedeemed.toString());
    if (request.IsExpired !== undefined) params.set('IsExpired', request.IsExpired.toString());
    if (request.CreatedAtStart !== undefined) params.set('CreatedAtStart', request.CreatedAtStart.toString());
    if (request.CreatedAtEnd !== undefined) params.set('CreatedAtEnd', request.CreatedAtEnd.toString());
    if (request.RedeemedAtStart !== undefined) params.set('RedeemedAtStart', request.RedeemedAtStart.toString());
    if (request.RedeemedAtEnd !== undefined) params.set('RedeemedAtEnd', request.RedeemedAtEnd.toString());
    if (request.ForAccountId !== undefined) params.set('ForAccountId', request.ForAccountId.toString());
    return this.http.get<Array<IVoucher>>(ServiceConfig.APIUrl() + "/voucher/search/",  { params: params });
  }
}

interface IRedeemVoucherRequest {
  AccountId: string;
  Code: string;
}
