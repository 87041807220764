import { PatchedSubscriber } from './observer/patched.subscriber';
import { isObservable } from './is.observable';

export function LoadingObservable() {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        const method = descriptor.value;
        const ngOnDestroy = target.ngOnDestroy;
        const subscriber = new PatchedSubscriber();

        descriptor.value = function (...args: any[]) {
            var result = method.apply(this, args);
            return isObservable(result) 
                ? subscriber.subscribe(propertyKey, result)
                : result;
        }

        target.ngOnDestroy = function() {
            subscriber.onDestroy();
            if (ngOnDestroy) {
                ngOnDestroy.apply(this, {});
            }
        }
    }
}