import { Component, OnInit, OnDestroy } from '@angular/core';
import { AccountsService } from '../services/accounts.service';
import { IAccountSummary } from '../entities/IAccountSummary';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html'
})
export class AccountsComponent implements OnDestroy, OnInit {

  public nav: any;
  accountSummaries: Array<IAccountSummary>;
  sortedData: IAccountSummary[];
  p: number;

  constructor(
    private accountsService: AccountsService,
    private _toastr: ToastrService) {
    this.nav = document.querySelector('nav.navbar');
  }

  public ngOnInit(): any {
    this.nav.className += ' white-bg';

    this.accountsService.accountSummaries().subscribe(
      accountSummaries => this.handleGetAccountSummariesResponse(accountSummaries),
      error => this.handleGetAccountSummariesError(error));
  }

  private handleGetAccountSummariesResponse(accountSummaries: Array<IAccountSummary>) {
    this.accountSummaries = accountSummaries;
    this.sortedData = this.accountSummaries.slice();
  }

  private handleGetAccountSummariesError(error: HttpErrorResponse) {
    this._toastr.error('Unexpected error - please try again later');
  }

  public ngOnDestroy(): any {
    this.nav.classList.remove('white-bg');
  }

  sortData(sort: Sort) {
    const data = this.accountSummaries.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return compareString(a.OwnersUsername, b.OwnersUsername, isAsc);
        case 'devices': return compare(a.DeviceSessionsCount, b.DeviceSessionsCount, isAsc);
        case 'subscriptions': return compare(a.SubscriptionsCount, b.SubscriptionsCount, isAsc);
        case 'vouchers': return compare(a.VouchersCount, b.VouchersCount, isAsc);
        case 'users': return compare(a.UsersCount, b.UsersCount, isAsc);
        default: return 0;
      }
    });

    function compare(a: number | string | Date | Boolean, b: number | string | Date | Boolean, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    function compareString(a: string, b: string, isAsc: boolean) {
      a = a || '';
      b = b || '';
      return (a.toLowerCase() < b.toLowerCase() ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }
}
