import { LoadingBus } from '../bus/loading.bus';

export class LoadingBusFactory {
    private static busInstance: LoadingBus;

    public static create(): LoadingBus {
        if (!this.busInstance) {
            this.busInstance = new LoadingBus();
        }
        return this.busInstance;
    }
}