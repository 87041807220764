import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { VoucherService } from '../voucher.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ICreateVouchersRequest } from '../entities/ICreateVouchersRequest';
import { ICreateVouchersResponse } from '../entities/ICreateVouchersResponse';
import { Subject } from 'rxjs';

@Component({
  selector: 'modal-content',
  templateUrl: './createvouchers.html'
})

export class CreateVouchers implements OnInit {
  title: string;
  closeBtnName: string;
  roles: string[];
  codes: Array<string>;
  public onClose: Subject<boolean>;

  public form: FormGroup;
  public numberOfDevices = new FormControl(undefined, [Validators.required, Validators.min(1)]);
  public numberOfVouchers = new FormControl(1, [Validators.required, Validators.min(1)]);
  public redeemBy = new FormControl(undefined, [Validators.required]);
  public validForInDays = new FormControl(undefined, [Validators.required]);
  public notes = new FormControl(undefined);

  constructor(
    public bsModalRef: BsModalRef,
    private voucherService: VoucherService,
    private _toastr: ToastrService) {

    this.form = new FormGroup(
      {
        'numberOfDevices': this.numberOfDevices,
        'numberOfVouchers': this.numberOfVouchers,
        'redeemBy': this.redeemBy,
        'validForInDays': this.validForInDays,
        'notes': this.notes,
      });
  }

  public controlIsValid(controlName: string) {
    const control = this.form.get(controlName);
    return (control.invalid && (control.dirty || control.touched));
  }

  public controlClasses(controlName: string) {
    return { 'is-invalid': this.controlIsValid(controlName) };
  }

  ngOnInit() {
    this.onClose = new Subject();
  }

  cancel() {
    this.form.reset();
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  save() {
    if (this.numberOfDevices.valid &&
        this.numberOfVouchers.valid && 
        this.notes.valid && 
        this.redeemBy.valid && 
        this.validForInDays.valid) {

      const request = {
        NumberOfDevices: this.numberOfDevices.value,
        NumberOfVouchers: this.numberOfVouchers.value,
        RedeemBy: this.redeemBy.value, 
        ValidForInDays: this.validForInDays.value,
        Notes: this.notes.value
      } as ICreateVouchersRequest;

      this.voucherService.create(request).subscribe(
        response => this.handleCreateVouchersResponse(response),
        error => this.handleCreateVouchersError(error));
    }
  }

  private handleCreateVouchersResponse(response: ICreateVouchersResponse) {
    if (response.IsOK) {
      this._toastr.success(response.Codes.length + ' voucher codes have been generated', 'Vouchers Created');
      this.codes = response.Codes;
      this.onClose.next(true);
      this.bsModalRef.hide();
    } else {
      this._toastr.error(response.Message, 'Failed to Create Vouchers');
    }
  }

  private handleCreateVouchersError(error: any) {
    this._toastr.error('Unexpected error - please try again later', 'Failed to Create Vouchers');
  }

}
