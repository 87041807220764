import { Component, OnInit } from '@angular/core';
import { IUser } from '../entities/IUser';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IOperationResponse } from '../entities/IOperationResponse';
import { userInfo } from 'os';

@Component({
  selector: 'modal-content',
  templateUrl: './editUser.html'
})

export class EditUser implements OnInit {
  title: string;
  closeBtnName: string;
  roles: string[];
  user: IUser;
  editedRoles: IEditedRoles;

  public form: FormGroup;
  public email = new FormControl(undefined, [Validators.required, Validators.email]);
  public name = new FormControl(undefined, [Validators.required]);
  public maxNumDeviceLicenses = new FormControl(undefined);
  public isTrusted = new FormControl(undefined);
  public ipadadmin = new FormControl(undefined);
  public subscriptionAdmin = new FormControl(undefined);
  public eventAdmin = new FormControl(undefined);
  public eventEditor = new FormControl(undefined);

  constructor(public bsModalRef: BsModalRef,
    private userService: UserService,
    private _toastr: ToastrService) {

    this.form = new FormGroup(
      {
        'email': this.email,
        'name': this.name,
        'maxNumDeviceLicenses': this.maxNumDeviceLicenses,
        'isTrusted': this.isTrusted,
        'ipadadmin': this.ipadadmin,
        'subscriptionAdmin': this.subscriptionAdmin,
        'eventAdmin': this.eventAdmin,
        'eventEditor': this.eventEditor,
      });
  }

  public controlIsValid(controlName: string) {
    const control = this.form.get(controlName);
    return (control.invalid && (control.dirty || control.touched));
  }

  public controlClasses(controlName: string) {
    return { 'is-invalid': this.controlIsValid(controlName) };
  }

  ngOnInit() {
    this.email.setValue(this.user.Email);
    this.name.setValue(this.user.Name);
    this.maxNumDeviceLicenses.setValue(this.user.MaxNumDeviceLicenses);
    this.isTrusted.setValue(this.user.IsTrusted);

    this.editedRoles = {
      EventAdmin: false,
      EventEditor: false,
      SubscriptionAdmin: false,
      iPadAdmin: false
    }
    this.userService.roles(this.user).subscribe(
      theRoles => this.handleLoadRoles(theRoles),
      error => this.handleLoadRolesException(error));
  }

  cancel() {
    this.form.reset();
    this.bsModalRef.hide();
  }

  save() {
    if (this.email.valid && this.name.valid) {
      this.user.Email = this.email.value;
      this.user.Name = this.name.value;
      this.user.MaxNumDeviceLicenses = this.maxNumDeviceLicenses.value;
      this.user.IsTrusted = this.isTrusted.value;

      this.editedRoles.iPadAdmin = this.ipadadmin.value;
      this.editedRoles.SubscriptionAdmin = this.subscriptionAdmin.value;
      this.editedRoles.EventAdmin = this.eventAdmin.value;
      this.editedRoles.EventEditor = this.eventEditor.value;

      this.userService.updateUser(this.user, this.editedRoles).subscribe(
        (response: any) => this.handleUpdateUser(response),
        error => this.handleUpadeUserError(error));
    }
  }

  private handleUpdateUser(response: IOperationResponse) {
    if (response.IsOK) {
      this._toastr.success('The user has been updated', 'User Updates');
      this.bsModalRef.hide();
    }
    else {
      this._toastr.error('Unexpected error - please try again later', 'Failed to Save User');
    }
  }

  private handleUpadeUserError(error: any) {
    this._toastr.error('Unexpected error - please try again later', 'Failed to Save User');
  }

  private handleLoadRoles(theRoles: string[]) {
    this.roles = theRoles;
    var that = this;
    this.roles.forEach(function (role) {
      that.editedRoles[role] = true;
    });

    this.ipadadmin.setValue(this.editedRoles.iPadAdmin);
    this.subscriptionAdmin.setValue(this.editedRoles.SubscriptionAdmin);
    this.eventAdmin.setValue(this.editedRoles.EventAdmin);
    this.eventEditor.setValue(this.editedRoles.EventEditor);
  }

  private handleLoadRolesException(error: any) {
    this._toastr.error('Unexpected error - please try again later', 'Failed to Get User Roles');
  }
}

export interface IEditedRoles {
  EventEditor: boolean;
  EventAdmin: boolean;
  SubscriptionAdmin: boolean;
  iPadAdmin: boolean;
}
