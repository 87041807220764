import { Component, OnInit } from '@angular/core';
import { AccountsService } from '../services/accounts.service';
import { Router, ActivatedRoute } from '@angular/router';
import { IEventGroup } from "../entities/IEventGroup";

@Component({
  selector: 'app-eventgroups',
  templateUrl: './eventgroups.component.html',
  styleUrls: ['./eventgroups.component.scss']
})
export class EventGroupsComponent implements OnInit {

  public nav: any;
  eventgroups: Array<IEventGroup>;
  accountId : string;

  constructor(private accountsService: AccountsService,
    private activatedroute: ActivatedRoute,
    private router: Router, ) {
    this.nav = document.querySelector('nav.navbar');
    this.accountId = this.activatedroute.snapshot.params['accountId'];
  }

  public ngOnInit(): any {
    this.nav.className += " white-bg";

    // EventGroups TODO
    this.eventgroups = new Array<IEventGroup>();
  }

  public ngOnDestroy(): any {
    this.nav.classList.remove("white-bg");
  }

}
